import Rollbar from 'rollbar'
import { ENDPOINTS } from '@constants/endpoints.constants'
interface ChunkedPostsParams {
  postType: 'BUILDING_BLOCKS' | 'ROUNDTRIPS' | 'POSTS' | 'PAGES'
  pagesToFetch: number
  itemsPerPage: number
  maxPages?: number
  additionalParams?: object
  startAtPage?: number
}

const rollbar = new Rollbar({
  accessToken:
    process.env.ROLLBAR_SERVER_TOKEN ||
    process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  environment: process.env.ROLLBAR_ENVIRONMENT,
})

const dispatchRollbarError = (errorMessage: string) => {
  rollbar.error(errorMessage, null, rollbarError => {
    if (rollbarError) {
      console.log('Rollbar error reporting failed:')
      console.log(rollbarError)
      return
    } else {
      console.log('Reported error to Rollbar: ', errorMessage)
    }
  })
}

export class PostTypeChunksAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  index = async ({
    postType,
    pagesToFetch,
    itemsPerPage,
    maxPages = 20,
    startAtPage = 2,
    additionalParams,
  }: ChunkedPostsParams) => {
    let currentPage = startAtPage
    const promises = []

    while (currentPage <= pagesToFetch && currentPage < maxPages) {
      const params: any = {
        per_page: itemsPerPage,
        _embed: 1,
        ...additionalParams,
      }

      params.page = currentPage

      const request = this.Client.get(ENDPOINTS[postType].INDEX, params)
      promises.push(request)

      currentPage++
    }

    try {
      const chunks = await Promise.allSettled(promises)
      const fulfilled = chunks.filter(p => p.status === 'fulfilled')
      const withData = fulfilled.map(p => p.value.data)
      return withData.flat()
    } catch (error) {
      dispatchRollbarError(
        `BSTR-3236 | Post type chunks error: ${error?.message}`
      )
      return []
    }
  }
}
