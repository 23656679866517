const ACF = '/acf'
const V1 = '/v1'
const V2 = '/v2'
const V3 = '/v3'
const WP = '/wp'
const GRAVITY_FORMS = '/gf'
const YOAST = '/wp-rest-yoast-meta'

export const ENDPOINTS = {
  BUILDING_BLOCKS: {
    INDEX: `${WP}${V2}/reise-baustein`,
    CATEGORIES: `${WP}${V2}/building_block_categories`,
    SHOW: id => `${WP}${V2}/building_block_categories${id ? `/${id}` : ``}`,
  },
  CATEGORIES: {
    INDEX: `${WP}${V2}/categories`,
  },
  FORMS: {
    INDEX: `${GRAVITY_FORMS}${V2}/public_forms`,
    SHOW: id => `${GRAVITY_FORMS}${V2}/public_forms${id ? `/${id}` : ``}`,
    SUBMIT: formId => `${GRAVITY_FORMS}${V2}/forms/${formId}/submissions`,
  },
  PAGES: {
    INDEX: `${WP}${V2}/pages`,
    SHOW: id => `${WP}${V2}/pages${id ? `/${id}` : ``}`,
  },
  POSTS: {
    INDEX: `${WP}${V2}/posts`,
    SHOW: id => `${WP}${V2}/posts${id ? `/${id}` : ``}`,
  },
  POST_CATEGORIES: {
    INDEX: `${WP}${V2}/categories`,
    SHOW: id => `${WP}${V2}/categories${id ? `/${id}` : ``}`,
  },
  ROUNDTRIPS: {
    INDEX: `${WP}${V2}/rundreise`,
    CATEGORIES: `${WP}${V2}/roundtrip_categories`,
    SHOW: id => `${WP}${V2}/rundreise${id ? `/${id}` : ``}`,
    SHOW_CATEGORY: id => `${WP}${V2}/roundtrip_categories${id ? `/${id}` : ``}`,
  },
  SITES: {
    INDEX: `${WP}${V2}/sites`,
  },
  TAXONOMIES: {
    INDEX: `${WP}${V2}/taxonomies`,
  },
  THEMES: {
    INDEX: `${ACF}${V3}/options/theme-settings`,
  },
  TRAVELPLAN: {
    INDEX: `${WP}${V2}/requests`,
  },
  PRODUCT_FILTERS: {
    INDEX: `${WP}${V2}/product-filters`,
    SHOW: postType =>
      `${WP}${V2}/product-filters${postType ? `/${postType}` : ``}`,
  },
  YOAST: {
    REDIRECTS: `${YOAST}${V1}/redirects`,
  },
  TRAVEL_PLAN: {
    SHOW: ({ uid, travelPlanId }) =>
      `${WP}${V2}/requests?uid=${uid}&travelplan=${travelPlanId}`,
  },
  DOTMAILER: {
    SUBMIT: `${WP}${V2}/dotmailer`,
  },
  ROBOTS: {
    INDEX: '/index.php?robots=1',
  },
}
